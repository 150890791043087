@import url(https://fonts.googleapis.com/css2?family=Gruppo&family=Open+Sans&display=swap);
:root {
  --font: "Open Sans", sans-serif;
  --header: "Gruppo", cursive;

  --ltrSea: #00ffd5;
  --ltSea: #0ec7a8;
  --mdSea: #018f77;
  --dkSea: #036656;

  --background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, var(--mdSea), var(--dkSea));
}

* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-family: var(--font);
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #000;
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #0ec7a8;
  background: var(--ltSea);
}
::-webkit-scrollbar-thumb:hover {
  background: #036656;
  background: var(--dkSea);
}

/* NAVBAR */

.navigation {
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px -3px 4px rgba(0, 0, 0, 0.8);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navigation a {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #000;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  padding: 5px 0 8px;
  letter-spacing: 1px;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.2s;
}
.navigation a:hover {
  color: #036656;
  color: var(--dkSea);
}

#up {
  position: fixed;
  bottom: 5px;
  right: 10px;
  font-size: 40px;
  z-index: 20;
}
#up a {
  color: rgb(14, 199, 168, 0.8);
  transition: all 0.5s;
}
#up a:hover {
  color: #0ec7a8;
  color: var(--ltSea);
}

/* HEADER */

.header {
  height: 100vh;
  position: relative;
  background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, #018f77, #036656);
  background: var(--background);
  overflow: hidden;
  z-index: 0;
}

.spike {
  width: 0;
  height: 0;
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 100px;
  border: 350px solid transparent;
  border-bottom: 350px solid #024b3f;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.spone {
  left: -350px;
  border: 500px solid transparent;
  border-bottom: 500px solid #016151;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.sptwo {
  left: 300px;
  border: 400px solid transparent;
  border-bottom: 400px solid #015748;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.spthree {
  left: 550px;
  border: 600px solid transparent;
  border-bottom: 600px solid #016b59;
  -webkit-filter: blur(1px);
          filter: blur(1px);
}

.headerInner {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  position: relative;
  top: 30vh;
  cursor: default;
  border-bottom: 1px dashed #fff;
}

#name {
  font-size: 8vw;
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  color: #fff;
  text-shadow: 1px 1px 0px #000, 2px 2px 0px #000, 3px 3px 0px #000,
    4px 4px 0px #000;
  margin-bottom: -20px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}

#webD {
  font-size: 6vw;
  text-align: right;
  padding-right: 20px;
  color: #fff;
  letter-spacing: 5px;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  text-shadow: 2px 2px 0px #000;
}

@-webkit-keyframes arrowBounce {
  from {
    bottom: 10px;
  }
  to {
    bottom: 20px;
  }
}

@keyframes arrowBounce {
  from {
    bottom: 10px;
  }
  to {
    bottom: 20px;
  }
}
.arrowDwn {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 48vw;
  font-size: 40px;
  -webkit-filter: drop-shadow(0px 5px 0px #000);
          filter: drop-shadow(0px 5px 0px #000);
  transition: all 0.2s;
}
.header:hover .arrowDwn {
  -webkit-animation-name: arrowBounce;
          animation-name: arrowBounce;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

/* BLURB */

.blurb {
  background: #fff;
  padding: 50px 0;
  text-align: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8), 0px -3px 4px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1;
}

.github {
  font-size: 60px;
  color: #036656;
  color: var(--dkSea);
  pointer-events: all !important;
  transition: all 1s;
}
.github:hover {
  color: #0ec7a8;
  color: var(--ltSea);
}

#piece {
  display: block;
  width: 50vw;
  max-width: 900px;
  margin: 0 auto 20px;
  text-align: justify;
  cursor: default;
}
#piece a {
  color: #036656;
  color: var(--dkSea);
  text-decoration: none;
  font-weight: bold;
}
#piece a:hover {
  color: #018f77;
  color: var(--mdSea);
}

/* LANGUAGES */

.languages {
  display: -webkit-flex;
  display: flex;
  background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, #018f77, #036656);
  background: var(--background);
  padding: 50px 50px 40px;
  position: relative;
  text-align: center;
  z-index: 0;
}

.languages > div {
  width: 100%;
}

.sect {
  color: #fff;
  width: 35vw;
  margin: 0 auto 10px;
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  text-shadow: 1px 1px 0px #000, 2px 2px 0px #000;
  font-size: 40px;
  border-bottom: 1px dashed;
  cursor: default;
}

.sectDiv div {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
}
.sectDiv div:last-child {
  margin-right: 0;
}

svg {
  width: 45px;
  pointer-events: none;
  margin-bottom: 10px;
  -webkit-filter: drop-shadow(2px 2px 1px #000);
          filter: drop-shadow(2px 2px 1px #000);
}
#html,
.html {
  fill: #e34f26;
}
#css,
.css {
  fill: #1572b6;
}
#js,
.js {
  fill: #f7df1e;
}
#php {
  fill: #777bb4;
}
#webpack,
.webpack {
  fill: #8dd6f9;
}
#react,
.react {
  fill: #61dafb;
}
#reactroute,
.reactroute {
  fill: #ca4245;
}
#firebase,
.firebase {
  fill: #ffca28;
}
#heroku {
  fill: #430098;
}
#vscode,
.vscode {
  fill: #007acc;
}
#npm,
.npm {
  fill: #cb3837;
}
#yarn {
  fill: #2c8ebb;
}
#git,
.git {
  fill: #f05032;
}
#jasmine {
  fill: #8a4182;
}
#jest,
.jest {
  fill: #c21325;
}
#prettier {
  fill: #f7b93e;
}
#eslint {
  fill: #4b32c3;
}
#babel {
  fill: #f9dc3e;
}
#node {
  fill: #339933;
}
#styled,
.styled {
  fill: #db7093;
}

/* PROJECTS */

.projects {
  background: #fff;
  padding: 50px 0;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8), 0px -3px 4px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1;
}

.newProj {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0 auto 40px;
  width: 90vw;
  max-width: 800px;
}

.newProj .pic {
  width: 80%;
  max-width: 400px;
  height: 15rem;
  max-height: 200px;
  margin: 10px auto 20px;
  box-shadow: 0px 0px 4px #000;
}

.descript div:nth-child(1) {
  text-align: center;
  font-size: 40px;
  color: #036656;
  color: var(--dkSea);
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  font-size: bold;
  text-shadow: 1px 1px 0px #000;
}

.descript div:nth-child(3) {
  text-align: justify;
}
.descript div:nth-child(3) a {
  color: #036656;
  color: var(--dkSea);
}

.descript div:nth-child(4) {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 15px auto 20px;
}

.descript svg {
  width: 40px;
  -webkit-filter: drop-shadow(1px 1px 1px #000);
          filter: drop-shadow(1px 1px 1px #000);
}
.descript span {
  display: inline-block;
  height: 30px;
}

.descript div:nth-child(5) {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  text-align: center;
}
.descript div:nth-child(5) a {
  width: 100%;
  color: #036656;
  color: var(--dkSea);
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 30px;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000;
  transition: all 0.5s;
}
.descript div:nth-child(5) a:hover {
  background: radial-gradient(closest-side, rgb(3, 102, 86, 0.1), transparent);
  letter-spacing: 4px;
  color: #0ec7a8;
  color: var(--ltSea);
}

.break {
  width: 90vw;
  max-width: 900px;
  height: 5px;
  border-radius: 50%;
  background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, #018f77, #036656);
  background: var(--background);
  margin: 0 auto 40px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.waldo .pic {
  background: center/cover url(/static/media/waldo.56c04927.png);
}
.waldo:hover .pic {
  background: center/cover url(/static/media/waldo.42013a12.gif),
    center/cover url(/static/media/waldo.56c04927.png);
}
.battleship:hover .pic {
  background: center/cover url(/static/media/battleship.badb6851.gif),
    center/cover url(/static/media/battleship.05439156.png);
}
.battleship .pic {
  background: center/cover url(/static/media/battleship.05439156.png);
}
.dinos:hover .pic {
  background: center/cover url(/static/media/dinos.2ff6443d.gif),
    center/cover url(/static/media/dinos.88634317.png);
}
.dinos .pic {
  background: center/cover url(/static/media/dinos.88634317.png);
}
.mockSite:hover .pic {
  background: center/cover url(/static/media/mock.9223e661.gif),
    center/cover url(/static/media/mock.614da42e.png);
}
.mockSite .pic {
  background: center/cover url(/static/media/mock.614da42e.png);
}

/* EXPERIENCE */

.experience {
  background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, #018f77, #036656);
  background: var(--background);
  padding: 60px 100px 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: #fff;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: relative;
  z-index: 0;
}

.half {
  width: 48%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.half div {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}

.faIcon {
  width: 70px;
  font-size: 50px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.exBlurb {
  width: 60%;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 20px;
  text-shadow: 1px 1px 0px #000;
  cursor: default;
}
h1 {
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  font-weight: normal;
  text-shadow: 1px 1px 0px #000, 2px 2px 0px #000;
}
.exBlurb span {
  font-size: 15px;
}
.exBlurb a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.5s;
}
.exBlurb a:hover {
  color: #00ffd5;
  color: var(--ltrSea);
}

/* SOCIALS */

#socials {
  text-align: center;
  padding: 20px 0 10px;
  font-size: 50px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8), 0px -3px 4px rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 1;
}

#socials a {
  margin-left: 5%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  color: #036656;
  color: var(--dkSea);
  transition: all 0.5s;
}
#socials a:first-child {
  margin-left: 0;
}
#socials a:hover {
  color: #0ec7a8;
  color: var(--ltSea);
}

/* FOOTER */

#footer {
  background: url("https://raw.githubusercontent.com/savwiley/portfolio/master/src/images/rocky-wall.png") fixed,
    radial-gradient(at center, #018f77, #036656);
  background: var(--background);
  text-align: center;
  padding-top: 10px;
  position: relative;
  z-index: 0;
}
#footer a {
  font-size: 60px;
  color: #00ffd5;
  color: var(--ltrSea);
  transition: all 0.5s;
}
#footer a:hover {
  color: #036656;
  color: var(--dkSea);
}

.contact-form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: left;
  width: 40vw;
  margin: auto;
  color: #fff;
  text-shadow: 1px 1px 0 #000;
}
.contact-form span {
  font-family: "Gruppo", cursive;
  font-family: var(--header);
  font-size: 40px;
  text-shadow: 1px 1px 0 #000, 2px 2px 0 #000;
  cursor: default;
}
input,
textarea {
  outline: none;
  padding: 5px;
  border: none;
  box-shadow: 0px 0px 4px #000;
  border-radius: 5px;
  margin-bottom: 10px;
}
input[type="submit"] {
  width: 100px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
  cursor: pointer;
  background: #fff;
  font-weight: bold;
  transition: all 0.5s ease-in-out;
}
input[type="submit"]:hover {
  background: #036656;
  background: var(--dkSea);
  color: #fff;
  box-shadow: 0px 0px 8px #000;
}

/* MOBILE */

@media (max-width: 750px) {
  .proj {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .pic,
  .desc {
    width: 100%;
  }
  .pic {
    height: 200px;
    -webkit-order: 1;
            order: 1;
  }
  .desc {
    -webkit-order: 2;
            order: 2;
  }
  .spzero {
    border: 150px solid transparent;
    border-bottom: 150px solid #024b3f;
  }
  .spone {
    border: 300px solid transparent;
    border-bottom: 300px solid #016151;
  }
  .sptwo {
    border: 280px solid transparent;
    border-bottom: 280px solid #015748;
  }
  .spthree {
    border: 400px solid transparent;
    border-bottom: 400px solid #016b59;
  }
  .contact-form {
    width: 60vw;
  }
}

@media (max-width: 680px) {
  .navigation a {
    font-size: 4vw;
  }
  #name {
    margin-bottom: 0;
    font-size: 10vw;
  }
  #piece {
    width: 80vw;
  }
  .languages {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px 0;
  }
  .sect {
    width: 80vw;
  }
  .sectDiv div {
    margin-bottom: 20px;
  }
  .experience {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px 0;
  }
  .half {
    width: 80%;
  }
  .exBlurb {
    width: 100%;
    text-align: center;
  }
  .socials {
    font-size: 12px;
  }
  .contact-form {
    width: 80vw;
  }
}

